import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import FormsLayout from "components/Layouts/FormsLayout";
import LoadingScreen from "components/LoadingScreen";

import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Dashboard pages
const AgentDashboard = Loadable(lazy(() => import("./pages/dashboards/AgentDashboard")));
const PartnerDashboard = Loadable(lazy(() => import("./pages/dashboards/PartnerDashboard")));

// Inventory pages
const AgentInventory = Loadable(lazy(() => import("./pages/inventory/AgentInventory")));


// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
        <DashboardLayout />
    ),
    children: [
      {
        path: "agent",
        element: <AgentDashboard />,
      },
      {
        path: "partner",
        element: <PartnerDashboard />,
      }
    ],
  },
  {
    path: "inventory",
    element: (
        <DashboardLayout />
    ),
    children: [
      {
        path: "agent",
        element: <AgentInventory />,
      }
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
